import { render, staticRenderFns } from "./TableColaboradoresE-Gov.vue?vue&type=template&id=34a0b37e&scoped=true&ref=tableCollaboratorsEgov"
import script from "./TableColaboradoresE-Gov.vue?vue&type=script&lang=js"
export * from "./TableColaboradoresE-Gov.vue?vue&type=script&lang=js"
import style0 from "./TableColaboradoresE-Gov.vue?vue&type=style&index=0&id=34a0b37e&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a0b37e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34a0b37e')) {
      api.createRecord('34a0b37e', component.options)
    } else {
      api.reload('34a0b37e', component.options)
    }
    module.hot.accept("./TableColaboradoresE-Gov.vue?vue&type=template&id=34a0b37e&scoped=true&ref=tableCollaboratorsEgov", function () {
      api.rerender('34a0b37e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/eSocial/components/TableColaboradoresE-Gov.vue"
export default component.exports