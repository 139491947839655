<template ref="tableCollaboratorsEgov">
  <v-card class="pa-0 colaboradores-card">
    <div id="colaboradores-container">
      <v-card-title class=" mb-4 mt-4 py-0">
        <TableSearch
          label="Pesquisar por nome, matrícula, CPF e RG"
          id="searchBarCollab"
          v-model="filter.nameCpfMatriculation"
          :disabled="companiesList.length < 1 || loadingTable"
          @input="onInputTableColaboradoresSearch"
        ></TableSearch>
        <v-spacer></v-spacer>

        <ExportButton :is-disabled="isDisabled" @exportar="exportar" />
      </v-card-title>

      <div :id="idTable + '_scrollDuplicate'" class="table_scroll_duplicate">
        <div :id="idTable + '_dummy'"></div>
      </div>

      <v-data-table
        id="tablec"
        :loading="loadingTable"
        :search="search"
        :headers="headers"
        :options.sync="pagination"
        :items="collaborators"
        :page="pagination.page"
        :server-items-length="totalCollaborators"
        :footer-props="{
          disablePagination: companiesList.length === 0 || loadingTable,
          itemsPerPageOptions:
            totalCollaborators < 50
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100', disabled: true },
                  { value: 250, text: '250', disabled: true },
                ]
              : totalCollaborators < 100
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250', disabled: true },
                ]
              : [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250' },
                ],
          disableItemsPerPage: collaborators.length === 0 || loadingTable,
          itemsPerPageText: `${funcTitle} por página: `,
          expandIcon: 'mdi-dots-horizontal',
        }"
        :no-data-text="`Não há registros de ${funcTitle} a serem exibidos.`"
        @current-items="onTableInput"
      >
        <template v-slot:footer.page-text="items">
          {{ loadingTable ? 0 : items.pageStart }} - {{ loadingTable ? 0 : items.pageStop }} de
          {{ loadingTable ? 0 : items.itemsLength }} {{ funcTitle }}
        </template>
        <template slot="body" v-if="loadingTable">
          <tr>
            <td v-for="loadItem in headers">
              <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
            </td>
          </tr>
        </template>
        <template v-slot:item.socialName="{ item }">
          <span style="display: inline-block; white-space: nowrap">
            {{ item.socialName }}
          </span>
        </template>
        <template v-slot:item.matriculation="{ item }">
          <span style="display: inline-block; white-space: nowrap">
            {{ item.matriculation }}
          </span>
        </template>
        <template v-slot:item.name="{ item }">
          <span style="display: inline-block; white-space: nowrap">
            {{ item.name != null ? start_and_end(item.name, 64).toUpperCase() : '' }}
          </span>
        </template>

        <template v-slot:item.categoryInfo="{ item }">
          <v-tooltip bottom style="width: 250px !important">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" style="display: inline-block; white-space: nowrap"
                >{{ item.categoryInfo != null ? start_and_end(item.categoryInfo, 64) : '' }}
              </span>
            </template>
            <span> {{ item.categoryInfo }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.categoryType="{ item }">
          <span style="display: inline-block; white-space: nowrap">{{ item.categoryType }} </span>
        </template>

        <template v-slot:item.cboInfo="{ item }">
          <v-tooltip bottom style="width: 320px !important">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" style="display: inline-block; white-space: nowrap">
                {{ item.cboInfo != null ? start_and_end(item.cboInfo, 64) : '' }}
              </span>
            </template>
            <span> {{ item.cboInfo }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.cnpj="{ item }">
          <span style="display: inline-block; white-space: nowrap">
            {{ item.cnpj | cnpj }}
          </span>
        </template>
        <template v-slot:item.birth="{ item }">
          {{ item.birth | date }}
        </template>
        <template v-slot:item.admissionDate="{ item }">
          {{ item.admissionDate | date }}
        </template>
        <template v-slot:item.detachDate="{ item }">
          {{ item.detachDate | date }}
        </template>

        <template v-slot:item.cpf="{ item }">
          <span style="display: inline-block; white-space: nowrap">
            {{ item.cpf | cpf }}
          </span>
        </template>
        <template v-slot:item.salary="{ item }">
          {{ item.salary | money }}
        </template>
        <template v-slot:no-data>
          <div id="noDataText" class="d-flex justify-center" :style="getTableWidth">
            <p>Não há registros de {{ funcTitle }} a serem exibidos.</p>
          </div>
        </template>
        <template v-slot:item.situation="{ item }">
          <span
            v-if="item.situation === 'active'"
            style="color: #288569; font-weight: 400; font-size: 16px; letter-spacing: 0.16px"
            >Ativo</span
          >
          <span
            v-if="item.situation === 'inactive'"
            style="color: #962d33; font-weight: 400; font-size: 16px; letter-spacing: 0.16px"
            >Inativo</span
          >
          <span
            v-if="item.situation === 'retired'"
            style="color: #d9c34c; font-weight: 400; font-size: 16px; letter-spacing: 0.16px"
            >Afastado</span
          >
          <span
            v-if="item.situation === 'vacation'"
            style="color: #288569; font-weight: 400; font-size: 16px; letter-spacing: 0.16px"
            >Férias</span
          >
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import FilterButton from './FilterForTable.vue';
import ExportButton from './ExportButton.vue';

import TableMixin from "@/mixins/Table.js"
import { eventBus } from '@/main.js';

export default {
  name: 'TableColaboradores',

  components: {
    FilterButton,
    ExportButton,
    TableSearch: () => import('../../commons/TableSearch.vue'),
  },

  mixins: [TableMixin],

  updated() {
    this.customize();
    this.changeIcon();
  },

  mounted() {
    this.changeIcon2();
    // this.prepareListeners();
    // this.disableCollaboratorsSearch();
  },
  watch: {
    collaborators(value) {
      if (value.length === 0) {
        this.search = '';
      }
    },
  },
  data() {
    return {
      idTable: 'tablec',
      funcTitle: process.env.VUE_APP_TAGFUNC,
      up: false,
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },

      search: '',

      headers: [
        { text: 'Empresa', value: 'socialName', width: '500px', sortable: true },
        { text: 'CNPJ', value: 'cnpj', width: '170px', sortable: true },
        { text: 'Matrícula', value: 'matriculation', width: '120px', sortable: true },
        { text: 'Nome', value: 'name', width: '220px', sortable: true },
        { text: 'CPF', value: 'cpf', width: '150px', sortable: true },
        { text: 'RG', value: 'rg', width: '140px', sortable: true },
        { text: 'Data de nasc.', value: 'birth', width: '140px', sortable: true },
        { text: 'UF', value: 'uf', width: '78px', sortable: true },
        { text: 'CEP', value: 'cep', width: '78px', sortable: true },
        { text: 'Tipo de categoria', value: 'categoryType', width: '350px', sortable: true },
        { text: 'Cód. de categoria', value: 'categoryCode', width: '180px', sortable: true },
        { text: 'Categoria', value: 'categoryInfo', width: '500px', sortable: true },
        { text: 'Cód. do CBO', value: 'cboCode', width: '150px', sortable: true },
        { text: 'Título do CBO', value: 'cboInfo', width: '500px', sortable: true },
        { text: 'Cargo', value: 'cargoInfo', width: '500px', sortable: true },
        { text: 'Situação', value: 'situation', width: '106px', sortable: true },
        { text: 'Admissão', value: 'admissionDate', width: '120px', sortable: true },
        { text: 'Desligamento', value: 'detachDate', width: '150px', sortable: true },
        // { text: 'Salário', value: 'salary', width: '150px', sortable: true },
      ],
      customStyleSkeleton:
        'text-align: start;color: #e7e7fa;font-family: "Roboto";font-weight: 700;font-size: 28px;letter-spacing: 1%;height: 51px;flex-direction: row;align-items: flex-start; border-left: 2px solid #ffffff',
    };
  },
  created() {
    this.init(this.userInfo.id);
  },

  computed: {
    ...mapGetters({
      companiesList: 'egov/getCompaniesList',
      collaborators: 'egov/getCollaborators',
      loadingTable: 'egov/getLoadingTable',
      paginationFilter: 'egov/getPagination',
      totalCollaborators: 'egov/getTotCollaborators',
      filter: 'egov/getFilter',
      userInfo: 'user/userInfo',
      scheduleReport: 'egov/getScheduleReport',
    }),
    pagination: {
      get: function () {
        return this.paginationFilter;
      },
      set: function (value) {
        this.setPagination(value);
      },
    },
    isDisabled() {
      if (this.collaborators.length < 1) {
        return true;
      } else if (this.companiesList.length < 1) {
        return true;
      } else if (this.loadingTable) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    changeIcon2() {
      const listItem = document.getElementsByClassName('material-icons-outlined');

      let selected = '';
      if (listItem[3] != undefined && listItem[3].innerHTML === 'expand_more') {
        selected = listItem[3];
        selected.style.color = 'primary';
        selected.style.fontSize = '24px';
        selected.style.fontWeight = 'regular';
      } else {
        const listItemNew = document.getElementsByClassName('v-input__icon v-input__icon--append')[0];
        const newITem = document.createElement('span');
        newITem.setAttribute('class', 'material-icons-outlined');
        newITem.innerText = 'expand_more';

        newITem.style.color = '#8686A2';
        newITem.style.fontSize = '24px';
        newITem.style.fontWeight = 'regular';
        listItemNew.parentNode.replaceChild(newITem, listItemNew);
      }
    },

    changeIcon() {
      const isDisabledOrNot = document.getElementsByClassName(
        'v-input v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--is-disabled theme--light v-text-field v-text-field--is-booted v-select',
      );
      if (isDisabledOrNot[0]) {
        const listItem = document.getElementsByClassName(
          'v-icon notranslate v-icon--disabled mdi mdi-menu-down theme--light',
        );

        const itemSelected = listItem[0];

        const newITem = document.createElement('span');
        newITem.setAttribute('class', 'material-icons-outlined');
        newITem.innerText = 'expand_more';

        newITem.style.color = '#8686A2';
        newITem.style.fontSize = '24px';
        newITem.style.fontWeight = 'regular';
        if (listItem[0]) {
          listItem[0].parentNode.replaceChild(newITem, itemSelected);
        }
      } else {
        const listItem = document.getElementsByClassName('material-icons-outlined');

        let selected = '';
        if (listItem[4] != undefined && listItem[4].innerHTML === 'expand_more') {
          selected = listItem[4];
          selected.style.color = 'var(--v-primary-base) ';
          selected.style.fontSize = '24px';
          selected.style.fontWeight = 'regular';
        }
      }
    },

    ...mapActions('egov', ['changeFilter', 'init', 'setPagination', 'exportCollaborators']),

    async exportar() {
      await this.exportCollaborators();
      if (this.scheduleReport) {
        this.showToast(
          'Recebemos seu pedido com sucesso! Nos menus Agnes em: Documentos e Download. Assim que concluído, o arquivo estará disponível.',
          'success',
          78,
        );
      } else {
        this.showToast('Erro ao exportar relatório.', 'error', 78);
      }
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
    onInputTableColaboradoresSearch(val) {
      this.changeFilter({ prop: 'nameCpfMatriculationRg', value: val });
    },
    customize() {
      let tableLines = document.querySelectorAll('#colaboradores-container tbody tr');
      if (tableLines && tableLines.length > 1) {
        for (let i = 0; i < tableLines.length; i++) {
          if (i % 2) {
            tableLines[i].style.backgroundColor = '#F7F7FD';
          }
        }
      }
    },
    start_and_end(str, lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },
    clickChip(value) {
      let buttonClosed = document.querySelector('#' + value + ' span button');
      buttonClosed.classList.remove('v-icon');
      buttonClosed.classList.add('v-icon-clicked');
    },
    disableCollaboratorsEgovSearch() {
      console.log(3);
      let collaboratorsTable = document.querySelector('#tablec');
      if (collaboratorsTable && collaboratorsTable.parentElement) {
        collaboratorsTable.parentElement.querySelector('.v-input__slot').style.pointerEvents = 'none';
      }
    },
    enableCollaboratorsEgovSearch() {
      let collaboratorsTable = document.querySelector('#tablec');
      if (collaboratorsTable && collaboratorsTable.parentElement) {
        collaboratorsTable.parentElement.querySelector('.v-input__slot').style.pointerEvents = 'all';
      }
    },
    async prepareListeners(e) {
      this.$root.$on('disableCollaboratorsEgovSearch', () => {
        this.disableCollaboratorsEgovSearch();
      });
      this.$root.$on('enableCollaboratorsEgovSearch', () => {
        this.enableCollaboratorsEgovSearch();
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import './src/design/components/table.scss';

::v-deep .v-data-table {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.colaboradores-card {
  border: 1px solid #e7e7fa !important;
  border-top-width: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

#iconInfo {
  font-variation-settings: 'FILL' 1;
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  vertical-align: middle !important;
  color: #c73c44;
}
#iconDone.material-symbols-outlined {
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  vertical-align: middle !important;
  color: #3cc79d;
}
#iconInfoText {
  vertical-align: middle !important;
  line-height: 24px !important;
  font-size: 16px !important;
}
#iconDoneText {
  vertical-align: middle !important;
  line-height: 24px !important;
  font-size: 16px !important;
}

::v-deep #tablec .v-select__selection {
  color: var(--v-primary-base) !important;
}
::v-deep #tablec .v-input--is-label-active:not(.v-input--is-disabled) .v-select__selection {
  color: var(--v-primary-base);
}
::v-deep #tablec .v-input--is-label-active:not(.v-input--is-disabled) .v-select__selection:hover {
  background-color: #e3ffee !important;
}

::v-deep .v-data-footer__icons-before:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  color: var(--v-primary-base);
  border-radius: 100% !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1 !important;
  padding: 0 !important;
}

::v-deep .v-data-footer__icons-after:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  opacity: 1 !important;
  transition: none !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn--disabled {
  padding: 0 !important;
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled {
  background-color: #e7e7fa !important;
  padding: 0 !important;
  border-radius: 100% !important;
}
</style>
