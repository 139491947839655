var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "pa-0 colaboradores-card" }, [
    _c(
      "div",
      { attrs: { id: "colaboradores-container" } },
      [
        _c(
          "v-card-title",
          { staticClass: "mb-4 mt-4 py-0" },
          [
            _c("TableSearch", {
              attrs: {
                label: "Pesquisar por nome, matrícula, CPF e RG",
                id: "searchBarCollab",
                disabled: _vm.companiesList.length < 1 || _vm.loadingTable,
              },
              on: { input: _vm.onInputTableColaboradoresSearch },
              model: {
                value: _vm.filter.nameCpfMatriculation,
                callback: function ($$v) {
                  _vm.$set(_vm.filter, "nameCpfMatriculation", $$v)
                },
                expression: "filter.nameCpfMatriculation",
              },
            }),
            _c("v-spacer"),
            _c("ExportButton", {
              attrs: { "is-disabled": _vm.isDisabled },
              on: { exportar: _vm.exportar },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "table_scroll_duplicate",
            attrs: { id: _vm.idTable + "_scrollDuplicate" },
          },
          [_c("div", { attrs: { id: _vm.idTable + "_dummy" } })]
        ),
        _c(
          "v-data-table",
          {
            attrs: {
              id: "tablec",
              loading: _vm.loadingTable,
              search: _vm.search,
              headers: _vm.headers,
              options: _vm.pagination,
              items: _vm.collaborators,
              page: _vm.pagination.page,
              "server-items-length": _vm.totalCollaborators,
              "footer-props": {
                disablePagination:
                  _vm.companiesList.length === 0 || _vm.loadingTable,
                itemsPerPageOptions:
                  _vm.totalCollaborators < 50
                    ? [
                        { value: 50, text: "50" },
                        { value: 100, text: "100", disabled: true },
                        { value: 250, text: "250", disabled: true },
                      ]
                    : _vm.totalCollaborators < 100
                    ? [
                        { value: 50, text: "50" },
                        { value: 100, text: "100" },
                        { value: 250, text: "250", disabled: true },
                      ]
                    : [
                        { value: 50, text: "50" },
                        { value: 100, text: "100" },
                        { value: 250, text: "250" },
                      ],
                disableItemsPerPage:
                  _vm.collaborators.length === 0 || _vm.loadingTable,
                itemsPerPageText: `${_vm.funcTitle} por página: `,
                expandIcon: "mdi-dots-horizontal",
              },
              "no-data-text": `Não há registros de ${_vm.funcTitle} a serem exibidos.`,
            },
            on: {
              "update:options": function ($event) {
                _vm.pagination = $event
              },
              "current-items": _vm.onTableInput,
            },
            scopedSlots: _vm._u([
              {
                key: "footer.page-text",
                fn: function (items) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.loadingTable ? 0 : items.pageStart) +
                        " - " +
                        _vm._s(_vm.loadingTable ? 0 : items.pageStop) +
                        " de " +
                        _vm._s(_vm.loadingTable ? 0 : items.itemsLength) +
                        " " +
                        _vm._s(_vm.funcTitle) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.socialName",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(item.socialName) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.matriculation",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(item.matriculation) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.name",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "white-space": "nowrap",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.name != null
                                ? _vm.start_and_end(item.name, 64).toUpperCase()
                                : ""
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.categoryInfo",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        staticStyle: { width: "250px !important" },
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            "white-space": "nowrap",
                                          },
                                        },
                                        "span",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.categoryInfo != null
                                            ? _vm.start_and_end(
                                                item.categoryInfo,
                                                64
                                              )
                                            : ""
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v(" " + _vm._s(item.categoryInfo))])]
                    ),
                  ]
                },
              },
              {
                key: "item.categoryType",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(_vm._s(item.categoryType) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.cboInfo",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        staticStyle: { width: "320px !important" },
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            "white-space": "nowrap",
                                          },
                                        },
                                        "span",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.cboInfo != null
                                              ? _vm.start_and_end(
                                                  item.cboInfo,
                                                  64
                                                )
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v(" " + _vm._s(item.cboInfo))])]
                    ),
                  ]
                },
              },
              {
                key: "item.cnpj",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.birth",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(_vm._f("date")(item.birth)) + " "),
                  ]
                },
              },
              {
                key: "item.admissionDate",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("date")(item.admissionDate)) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.detachDate",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(_vm._f("date")(item.detachDate)) + " "),
                  ]
                },
              },
              {
                key: "item.cpf",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm._f("cpf")(item.cpf)) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.salary",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(_vm._f("money")(item.salary)) + " "),
                  ]
                },
              },
              {
                key: "no-data",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-center",
                        style: _vm.getTableWidth,
                        attrs: { id: "noDataText" },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Não há registros de " +
                              _vm._s(_vm.funcTitle) +
                              " a serem exibidos."
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.situation",
                fn: function ({ item }) {
                  return [
                    item.situation === "active"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#288569",
                              "font-weight": "400",
                              "font-size": "16px",
                              "letter-spacing": "0.16px",
                            },
                          },
                          [_vm._v("Ativo")]
                        )
                      : _vm._e(),
                    item.situation === "inactive"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#962d33",
                              "font-weight": "400",
                              "font-size": "16px",
                              "letter-spacing": "0.16px",
                            },
                          },
                          [_vm._v("Inativo")]
                        )
                      : _vm._e(),
                    item.situation === "retired"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#d9c34c",
                              "font-weight": "400",
                              "font-size": "16px",
                              "letter-spacing": "0.16px",
                            },
                          },
                          [_vm._v("Afastado")]
                        )
                      : _vm._e(),
                    item.situation === "vacation"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#288569",
                              "font-weight": "400",
                              "font-size": "16px",
                              "letter-spacing": "0.16px",
                            },
                          },
                          [_vm._v("Férias")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          },
          [
            _vm.loadingTable
              ? _c("template", { slot: "body" }, [
                  _c(
                    "tr",
                    _vm._l(_vm.headers, function (loadItem) {
                      return _c(
                        "td",
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "loaderCss",
                            attrs: { "max-width": loadItem.width },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }