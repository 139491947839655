var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "btn-exportar-enabled ml-2 border-0",
      staticStyle: { width: "127px" },
      attrs: { id: "btnExport", disabled: _vm.isDisabled, icon: "" },
      on: {
        click: function ($event) {
          return _vm.exportar()
        },
      },
    },
    [_c("v-icon", [_vm._v("mdi-download-outline")]), _vm._v(" Exportar ")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }