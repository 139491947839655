<template>
  <v-btn
    id="btnExport"
    class="btn-exportar-enabled ml-2 border-0"
    @click="exportar()"
    :disabled="isDisabled"
    style="width: 127px"
    icon
  >
    <v-icon>mdi-download-outline</v-icon> Exportar
  </v-btn>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['isDisabled'],
  name: 'ExportButton',
  components: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
    }),
  },

  async created() {},
  methods: {
    ...mapActions('wss', ['init']),
    exportar() {
      this.$emit('exportar', '');
    },
  },
};
</script>
<style scoped>
.btn-exportar-enabled .mdi-download-outline {
  margin-right: 8px !important;
}
.btn-exportar-enabled:hover .mdi-download-outline {
  margin-right: 8px !important;
}
.btn-exportar-enabled {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;
  vertical-align: middle !important;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid #fff;
  background-color: #fff !important;
  color: var(--v-primary-base);
}
.btn-exportar-enabled:active {
  border: 1px solid primary !important;
  background-color: '#fff';
  box-shadow: none !important;

}

.btn-exportar-enabled:hover {
  background: #e3ffee !important;
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;
  border-radius: 4px !important;
  border: 1px solid #e3ffee !important;
}

.btn-exportar-enabled:disabled {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;

  /* Typography  */
  font-family: 'Roboto', sans-serif;
  border: 1px solid #e7e7fa !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
}
.btn-exportar-enabled:disabled .mdi-download-outline {
  color: #8686a2 !important;
  width: 24px !important;
  height: 24px !important;
  font-weight: 700 !important;
}
</style>
